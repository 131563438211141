import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CurrencyPipe } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';


import { AppComponent } from './app.component';

// SE New Components
import { SeFeAlertModule } from 'se-fe-alert';
import { SeFeIconModule, seFeIconCalendar, seFeIconFlag, seFeIconPlusCircleOutline, seFeIconRemove } from 'se-fe-icon';
import { SeFeBlockModule } from 'se-fe-block';
import { SeFeButtonModule } from 'se-fe-button';
import { SeFePopoverModule } from 'se-fe-popover';
import { SeFeSpinnerModule } from 'se-fe-spinner';
import { SeFeTooltipModule } from 'se-fe-tooltip';
import { SeFeModalModule } from 'se-fe-modal';
import { SeFeScrollModule } from 'se-fe-scroll';

@NgModule({
  bootstrap: [ AppComponent ],
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    MatDatepickerModule,
    SeFeAlertModule,
    SeFeBlockModule,
    SeFeButtonModule,
    SeFeIconModule.include([ seFeIconCalendar, seFeIconFlag, seFeIconPlusCircleOutline, seFeIconRemove ]),
    SeFePopoverModule,
    SeFeSpinnerModule,
    SeFeAlertModule,
    SeFeTooltipModule,
    SeFeModalModule,
    SeFeScrollModule,
  ],
  providers: [CurrencyPipe],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ]
})
export class AppModule { }
